import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class Utils extends Vue {
    /**
     * Tries to extract an error message from the given response, which will
     * work if the response is a UserErrorMessage, or an AxiosResponse
     * containing such a message as its data. Otherwise, we return the given
     * default message, or a common default message if none is specified.
     *
     * @param response The response.
     * @param defaultMessage The optional default message.
     */
    public extractErrorMessage(response: any, defaultMessage?: string): string {
        if (response && response.data && response.data.type === "USER_MESSAGE") {
            return response.data.errorMessage;
        } else if (response && response.type === "USER_MESSAGE") {
            return response.errorMessage;
        } else if (defaultMessage) {
            return defaultMessage;
        } else {
            return "Något gick fel. Prova gärna igen."
        }
    }

    public isAdmin(user: any): boolean {
        if (user) {
            for (let i = 0; i < user.roles.length; i++) {
                if (user.roles[i].authority === 'ROLE_ADMIN') {
                    return true;
                }
            }
        }
        return false;
    }

    public isEmptyString(string: string): boolean {
        return string === null || string === "";
    }

    public getDisplayName(user: any): string {
        if (user) {
            return this.isEmptyString(user.name) ? user.username : user.name;
        }
        return "";
    }

    /**
     * Returns undefined if the string is "falsy". Otherwise, the string itself
     * is returned.
     *
     * @param s The string.
     */
    public falsyToUndefined(s: string): string | undefined {
        return !s ? undefined : s;
    }
}