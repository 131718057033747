























import {Component, Emit, Mixins, Prop} from "vue-property-decorator";
import {
    FrontendDeliveryDefinition,
    isNotMock,
    MockFrontendDeliveryDefinition
} from "@/models/frontend-delivery-definition";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import EditableColumnOfFields, {
    createField,
    Field
} from "@/components/EditableColumnOfFields.vue";
import {
    CreateDeliveryDefinitionRequest,
    UpdateDeliveryDefinitionRequest
} from "@/models/create-delivery-definition-request";
import {
    FtpConfiguration,
    FtpConfigurationPropertyList
} from "@/models/ftp-configuration";
import {ExportTypeAsList} from "@/models/frontend-export-filter";
import Utils from "@/mixins/utils";

/**
 * Frontend component to render an edit a DeliveryDefinition.
 */
@Component({components: {DeliveryDefinitionFields: EditableColumnOfFields, ReadOnlyField}})
export default class DeliveryDefinition extends Mixins(Utils) {

    @Prop()
    deliveryDefinition: FrontendDeliveryDefinition | MockFrontendDeliveryDefinition;

    updatedFields: Field[];

    showForm: boolean = false;


    get fields(): Field[] {
        return this.deliveryMainFields;
    }

    get deliveryMainFields(): Field[] {
        const deliv = this.deliveryDefinition;
        const ftp = this.deliveryDefinition.ftpConfiguration;
        const strings = DeliveryDefinition.fieldStrings();
        return [
            createField(strings.id, deliv.id, false),
            createField(strings.name, deliv.name),
            createField(strings.exportType, deliv.type, true, undefined, ExportTypeAsList()),
            createField(strings.customer, deliv.customer),
            createField(strings.includeFilePattern, deliv.includeFilePattern),
            createField(strings.includeNewerThan, deliv.includeNewerThan ? this.format_date(deliv.includeNewerThan) : ""),
            createField(strings.created, "created" in deliv ? this.format_date(deliv.created) : "", false),
            createField(strings.active, deliv.active, true, 'checkbox'),
            createField(strings.exportFilterId, deliv.exportFilter ? deliv.exportFilter.id.toString() : ""),
            createField(strings.ftpProtocol, ftp.ftpProtocol),
            createField(strings.ftpDirectory, ftp.directory),
            createField(strings.ftpHost, ftp.hostName),
            createField(strings.ftpPort, ftp.port),
            createField(strings.ftpPassiveMode, ftp.passiveMode, true, 'checkbox'),
            createField(strings.ftpBinaryMode, ftp.binaryMode, true, 'checkbox'),
            createField(strings.ftpProperties, ftp.properties, true, undefined, FtpConfigurationPropertyList()),
            createField(strings.ftpUsername, ftp.username),
            createField(strings.ftpPassword, ftp.password)
        ]
    }

    /**
     * Formats a date to YYYY-MM-DD HH:mm:ss
     * @param date
     */
    format_date(date: Date): string | null {
        const iso = this.format_date_helper(date);
        if(!iso) {
            return null;
        }
        return iso.slice(0, 10) + " " + iso.slice(11, 19);
    }

    /**
     * Inverse of {@link format_date}. Expects a string of type YYYY-MM-DD HH:mm:ss
     */
    date_from_string(str: string): string | null {
        if(!str) {
            return null;
        }
        let d = new Date(str);
        return this.format_date_helper(d);
    }
    
    format_date_helper(date: Date): string {
        return date.getFullYear()+"-"+this.addLeadingZero(date.getMonth()+1)+"-"+this.addLeadingZero(date.getDate())+"T"+this.addLeadingZero(date.getHours())+":"+this.addLeadingZero(date.getMinutes())+":"+this.addLeadingZero(date.getSeconds())+"."+this.addLeadingZero(date.getMilliseconds(),3);
    }
    
    addLeadingZero(num: number,len: number = 2): string {
        let numStr = num.toString();
        if (numStr.length < len) {
            return "0".repeat(len-numStr.length) + numStr;
        }
        return numStr;
    }

    private fieldsToDeliveryUpdate(fields: Field[]): CreateDeliveryDefinitionRequest {
        const fieldsData = Object.assign({}, ...fields.map(field => ({[field.name]: field.value})))
        const s = DeliveryDefinition.fieldStrings();
        const getField = (fieldName: string) => (fieldsData[fieldName])
        const ftpConfig: FtpConfiguration = {
            port: getField(s.ftpPort),
            password: getField(s.ftpPassword),
            hostName: getField(s.ftpHost),
            ftpProtocol: getField(s.ftpProtocol),
            passiveMode: getField(s.ftpPassiveMode),
            username: getField(s.ftpUsername),
            directory: getField(s.ftpDirectory),
            binaryMode: getField(s.ftpBinaryMode),
            properties: getField(s.ftpProperties)
        }
        return {
            name: fieldsData[s.name],
            exportType: fieldsData[s.exportType],
            customer: fieldsData[s.customer],
            exportFilterId: fieldsData[s.exportFilterId],
            includeFilePattern: this.falsyToUndefined(fieldsData[s.includeFilePattern]),
            // Remove trailing Z in timestamp
            includeNewerThan: this.date_from_string(fieldsData[s.includeNewerThan]),
            isActive: fieldsData[s.active],
            ftpConfiguration: ftpConfig
        }
    }

    private static fieldStrings() {
        return {
            id: "Id",
            name: "Namn",
            exportType: "Typ",
            customer: "Kund",
            includeFilePattern: "Inkluderar filer som matchar",
            includeNewerThan: "Inkluderar filer nyare än",
            created: "Skapad",
            active: "Aktiv",
            exportFilterId: "Filter id",
            ftpProtocol: "FTP Protokoll",
            ftpDirectory: "FTP Mapp",
            ftpHost: "FTP Host",
            ftpUsername: "FTP Användarnamn",
            ftpPassword: "FTP Lösenord",
            ftpFilePattern: "FTP include file pattern",
            ftpPort: "FTP port",
            ftpPassiveMode: "FTP passivt läge",
            ftpProperties: "FTP egenskaper",
            ftpBinaryMode: "FTP binärt läge"
        }
    }

    submitChanges() {
        const delivDef: CreateDeliveryDefinitionRequest = this.fieldsToDeliveryUpdate(this.updatedFields);
        if (isNotMock(this.deliveryDefinition)) {
            const updateDelivDef: UpdateDeliveryDefinitionRequest = Object.assign(delivDef, {"id": this.deliveryDefinition.id});
            this.updateDeliveryDef(updateDelivDef);
        } else {
            this.createDeliveryDef(delivDef);
        }
    }

    @Emit()
    removeDeliveryDef(): undefined {
        return;
    }

    @Emit()
    createDeliveryDef(deliveryDef: CreateDeliveryDefinitionRequest): CreateDeliveryDefinitionRequest {
        return deliveryDef;
    }

    @Emit()
    updateDeliveryDef(deliveryDef: UpdateDeliveryDefinitionRequest): UpdateDeliveryDefinitionRequest {
        return deliveryDef;
    }


}
